<template>
  <section class="container pb-4">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row class="d-flex justify-center align-center mb-5">
      <div class="d-flex flex-column justify-center align-center mt-3 mb-3">
        <img width="40%" src="../../assets/cad_cadastro.png" alt="" />
      </div>
    </v-row>
    <div class="d-flex flex-column justify-space-between">
      <v-row class="row_buttons">
        <v-col cols="12" md="4" class="d-flex align-end pos-relative">
          <router-link :to="{ name: 'sobre_contexto_conquistas' }">
            <div class="dashed-01 d-none d-md-flex">
              <img
                style="transform: rotate(-40deg);"
                src="../../assets/dashed_03.png"
                alt=""
              />
            </div>
            <FlagTitle class="overall" title="Contexto e Conquista" />
          </router-link>
        </v-col>
        <v-col class="d-flex align-start pos-relative">
          <router-link :to="{ name: 'sobre_reformulacao_cadastro' }">
            <FlagTitle class="overall" title="Formulário" />
            <div class="dashed-02 d-none d-md-flex">
              <img width="45%" src="../../assets/dashed_02.png" alt="" />
            </div>
          </router-link>
        </v-col>
        <v-col class="d-flex align-center pos-relative">
          <router-link :to="{ name: 'sobre_sistematizacao_dossie' }">
            <FlagTitle class="overall" title="Sistematização e Dossiê" />
            <div class="dashed-04 d-none d-md-flex">
              <img
                width="50%"
                height="100%"
                src="../../assets/dashed_04.png"
                alt=""
              />
            </div>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="row_buttons d-flex justify-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-row-reverse align-center pos-relative"
        >
          <router-link :to="{ name: 'sobre_vistoria_advi' }">
            <div class="dashed-03 d-none d-md-flex">
              <img
                width="40%"
                height="100%"
                src="../../assets/dashed_03.png"
                alt=""
              />
            </div>

            <FlagTitle class="overall" title="Vistoria / ADVI" />
          </router-link>
        </v-col>
        <v-col cols="12" md="4" class="d-flex align-end justify-center">
          <router-link :to="{ name: 'sobre_tomadas_de_termo' }">
            <FlagTitle class="overall" title="Tomadas de Termo" />
          </router-link>
        </v-col>
      </v-row>
      <v-row cols="12" md="4" class="row_buttons">
        <v-col class="d-flex align-center justify-start pos-relative">
          <router-link :to="{ name: 'sobre_cartografia_social_familiar' }">
            <FlagTitle class="overall" title="Cartografia Social Familiar" />
            <div class="dashed-05 d-none d-md-flex">
              <img width="50%" src="../../assets/dashed_05.png" alt="" />
            </div>
          </router-link>
        </v-col>
        <v-col class="d-flex align-end justify-end pos-relative">
          <div class="dashed-06 d-none d-md-flex">
            <img width="50%" src="../../assets/dashed_06.png" alt="" />
          </div>
          <router-link :to="{ name: 'sobre_ati_continuada' }">
            <FlagTitle class="overall" title="Por uma ATI continuada" />
          </router-link>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb.vue";
import FlagTitle from "../../components/FlagTitle.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: { Breadcrumb, FlagTitle, GoBack },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Quem somos /",
          href: "cards",
        },
        {
          text: "Assessoria Técnica /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "O Cadastro",
          href: "sobre_cadastro",
        },
      ],
    };
  },
};
</script>

<style>
.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.row_buttons {
  height: 19vh;
}

.row_buttons a {
  text-decoration: none;
}

.dashed-01 {
  position: absolute;
  bottom: 70px;
  left: 150px;
  z-index: 1;
}

.dashed-02 {
  position: absolute;
  left: -220px;
  z-index: 1;
}

.dashed-05 {
  position: absolute;
  top: -50px;
  right: 30px;
  z-index: 1;
}

.dashed-03 {
  position: absolute;
  right: -600px;
  top: -40px;
  z-index: 1;
}

.dashed-04 {
  position: absolute;
  top: 70%;
  right: 50%;
  z-index: 1;
}

.dashed-06 {
  position: absolute;
  bottom: 3em;
  z-index: 1;
}

.overall {
  z-index: 10;
}

@media (max-width: 600px) {
  .dashed-01,
  .dashed-02,
  .dashed-03,
  .dashed-04,
  .dashed-05,
  .dashed-06 {
    display: relative;
  }
}

@media (min-width: 1900px) {
  .dashed-01 {
    width: 300px;
    bottom: 70px;
    left: 300px;
    transform: rotate(20deg);
  }
  .dashed-02 {
    left: -300px;
    top: 150px;
    transform: rotate(20deg);
  }
  .dashed-03 {
    right: -600px;
    top: -40px;
  }
  .dashed-04 {
    top: 150px;
    right: 400px;
  }
  .dashed-05 {
    top: 0px;
    right: 150px;
    transform: rotate(20deg);
  }
  .dashed-06 {
    bottom: 4em;
    right: 5em;
    transform: rotate(-20deg);
  }
}
</style>
